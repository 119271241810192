import { Controller } from "@hotwired/stimulus";
import Trix from "trix";

// Connects to data-controller="trix-editor"
export default class extends Controller {
  static targets = ["mainEditor", "selectVideoButton", "mainEditorVideoVariable", "selectVideoBtn", "modal", "modalEditor", "modalEditorVideoVariable"];
  static values = {
    videoPlaceholder: String,
  };

  connect() {
    this.initializeEditor(this.mainEditorTarget)
    if (this.hasModalEditorTarget) {
      this.initializeEditor(this.modalEditorTarget)
    }
  }

  initializeEditor(element) {
    element.addEventListener("trix-initialize", () => {
      // Ensure Trix is initialized before adding custom toolbar buttons and dialogs
      const toolbar = element.toolbarElement.querySelector(".trix-button-group--block-tools");
      const dialogs = element.toolbarElement.querySelector(".trix-dialogs");
      const editor = element.getAttribute('data-trix-editor-target')

      if (toolbar && dialogs) {
        this.addCustomToolbarButtons(toolbar)
        // Add custom button
        this.addSelectVideoPlaceholder(editor, toolbar, dialogs)
      }
    });
  }

  addCustomToolbarButtons(toolbar) {
    // Add underline and heading buttons
    const buttons = `
      <button type="button" class="trix-button" data-trix-attribute="underline" title="Underline" tabindex="-1">U</button>
      <button type="button" class="trix-button" data-trix-attribute="heading1" title="Heading 1" tabindex="-1">H1</button>
    `;

    // Add custom buttons to the toolbar
    toolbar.insertAdjacentHTML("afterbegin", buttons);

    // Configure Trix for new attributes
    this.configureTrixAttributes();
  }

  configureTrixAttributes() {
    // Underline
    Trix.config.textAttributes.underline = {
      tagName: "u", // HTML tag for underline
    };

    // Headings
    Trix.config.blockAttributes.heading1 = {
      tagName: "h1",
      breakOnReturn: true, // Create new block on enter
    };
  }

  placeholderVideosOptions() {
    let options = '<option value="">Select Videos</option>';
    const data = JSON.parse(this.videoPlaceholderValue);
    Object.entries(data).forEach(([key, value]) => {
      options += `<option value="${value}">${key}</option>`;
    });
    return options;
  }

  addSelectVideoPlaceholder(editor, toolbar, dialogs) {
    if(this.videoPlaceholderValue) {
      const btn = `
        <button type="button" class="trix-button" data-trix-attribute="video_placeholder" title="VideoPlchldr" tabindex="-1">Videos</button>
      `;
      toolbar.insertAdjacentHTML("afterbegin", btn);

      // Add custom dialog
      const dialog = `
        <div class="trix-dialog trix-dialog--placeholder" data-trix-dialog="video_placeholder" data-trix-dialog-attribute="video_placeholder">
          <div class="trix-dialog__link-fields">
            <select name="video_variable_tag" style="margin-right: 10px" data-trix-editor-target="${editor}VideoVariable">
              ${this.placeholderVideosOptions()}
            </select>
            <div class="trix-button-group">
              <input type="button" id="selectVideoBtn" class="trix-button trix-button--dialog" value="Select" data-action="click->trix-editor#setVideoSelected" data-trix-editor-target="selectVideoBtn" data-trix-method="removeAttribute" data-source="${editor}">
              <input type="button" class="trix-button trix-button--dialog" value="Cancel" data-trix-method="removeAttribute">
            </div>
          </div>
        </div>
      `;
      dialogs.insertAdjacentHTML("beforeend", dialog);
    }
  }

  setVideoSelected(e) {
    const editor = e.currentTarget.getAttribute('data-source')
    if(editor == "mainEditor") {
      const value = this.mainEditorVideoVariableTarget.value;
      this.mainEditorTarget.editor.insertHTML(value);
    } else if(editor == "modalEditor") {
      const value = this.modalEditorVideoVariableTarget.value;
      this.modalEditorTarget.editor.insertHTML(value);
    }
  }

  openModal() {
    const modal = this.modalTarget;
    // Get the HTML content from the main editor
    const currentContent = this.mainEditorTarget.innerHTML;

    // Set the content in the modal editor
    this.modalEditorTarget.editor.loadHTML(currentContent);
    modal.classList.remove("hidden");
    modal.classList.add("flex");
  }

  closeModal() {
    const modal = this.modalTarget;
    modal.classList.remove("flex");
    modal.classList.add("hidden");
  }

  copyToMainEditor() {
    const currentContent = this.modalEditorTarget.innerHTML;
    this.mainEditorTarget.editor.loadHTML(currentContent);
    // Manually dispatch an input event to trigger the auto-submit action
    const event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });
    this.mainEditorTarget.dispatchEvent(event);

    this.closeModal()
  }
}
