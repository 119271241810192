import { Controller } from "@hotwired/stimulus";
  
export default class extends Controller {
  static targets = ["selectTemplate", "spinner"];
  
  connect() {
  }

  load(event) {
    console.log("load template")
    this.showSpinner();
    const selectedValue = this.selectTemplateTarget.value;
    const trixEditor = document.querySelector('trix-editor[input="eml_template_body"]');

    fetch(`/eml/templates/${selectedValue}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }  
    })  
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);  
      }  
      return response.json();
    })
    .then(data => {
      // Use the loadHTML method to set the content
      trixEditor.editor.loadHTML(data.body);
    })
    .catch(error => {  
      console.error('There was a problem with the fetch operation:', error);
      // Use the loadHTML method to set the content
      trixEditor.editor.loadHTML("");
    })
    .finally(() => {
      // Hide the spinner after the request is complete  
      this.hideSpinner();
    });
  }
  
  showSpinner() {
    if (this.spinnerTarget) {
      this.spinnerTarget.classList.remove("hidden");  
    }
  }
  
  hideSpinner() {
    if (this.spinnerTarget) {
      this.spinnerTarget.classList.add("hidden");  
    }  
  }  
}  
