import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "message"];

  copy() {
    // Select the input value
    this.inputTarget.select();
    // Copy to clipboard
    navigator.clipboard.writeText(this.inputTarget.value).then(() => {
      // Show success message
      this.messageTarget.classList.remove("hidden");
      setTimeout(() => {
        this.messageTarget.classList.add("hidden");
      }, 2000);
    });
  }

  hide() {
    // Hide the entire component
    this.element.classList.add("hidden");
  }
}
