import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "dropzone"]

  connect() {
    this.itemTargets.forEach((element) => {
      element.setAttribute("draggable", true)
    })
  }

  dragstart(event) {
    event.dataTransfer.setData("text/plain", event.target.dataset.id)
    event.target.classList.add("opacity-50")
    this.draggedElement = event.target
  }

  dragend(event) {
    event.target.classList.remove("opacity-50")
    this.dropzoneTargets.forEach(dropzone => {
      dropzone.classList.remove("dropzone-active")
    })
  }

  dragover(event) {
    event.preventDefault()

    
    this.dropzoneTargets.forEach(dropzone => {
      dropzone.classList.remove("dropzone-active")
    })
    
    const draggedRect = this.draggedElement.getBoundingClientRect()
    const targetRect = event.currentTarget.getBoundingClientRect()
    if (event.clientY > draggedRect.top && event.clientY < draggedRect.bottom) {
      // Do not highlight if the current target is directly above the dragged element
      return
    }

    // Get the next and previous elements
    const nextElement = this.draggedElement.nextElementSibling

    // Prevent dragging to the next or previous element
    if (event.currentTarget === nextElement || event.currentTarget === this.draggedElement) {
      return
    }

    event.currentTarget.classList.add("dropzone-active")
  }

  drop(event) {
    event.preventDefault()
    event.stopPropagation()

    const draggedId = event.dataTransfer.getData("text/plain")
    const dropTargetId = event.currentTarget.dataset.id
    const newPosition = event.currentTarget.dataset.position // Extract the new position from data-position

    if (draggedId !== dropTargetId) {
      const url = this.draggedElement.dataset.url 
      this.sendReorderRequest(url, newPosition)
    }
  }

  sendReorderRequest(url, newPosition) {
    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({
        ask_question: {
          position: newPosition
        }
      })
    })
    .then(response => response.text())
    .then(Turbo.renderStreamMessage)
  }
}
