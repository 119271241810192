import { Controller } from "@hotwired/stimulus"
import {basicSetup, EditorView} from "codemirror"
import {autocompletion} from "@codemirror/autocomplete"
import { yaml } from "@codemirror/lang-yaml"
import { javascript } from "@codemirror/lang-javascript"

export default class extends Controller {
  static targets = ["editor", "input"]
  static values = {
    doc: String,
    // TODO: make readonly work here:
    readonly: Boolean
  }

  connect() {
    this.editor = new EditorView({
      doc: this.docValue,
      extensions: [
        basicSetup,
        javascript(),
        yaml(),
        autocompletion({override: [this.completions]}),
        EditorView.updateListener.of((view) => {
          if (view.docChanged) { this.sync() }
        })
      ],
      parent: this.editorTarget
    })

    this.editor.dom.addEventListener('input', () => {
      this.sync()
    })
  }

  disconnect() {
    this.editor.destroy()
  }

  sync() {
    this.inputTarget.value = this.editor.state.doc.toString()
    this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }))
  }

  completions(context) {
    let word = context.matchBefore(/\w*/)
    if (word.from == word.to && !context.explicit)
      return null

    return {
      from: word.from,
      options: [
        { label: "User", type: "constant", info: "The User model" }
      ]
    }
  }
}
