import { Controller } from "@hotwired/stimulus"
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";

export default class extends Controller {
  static targets = ["editor", "fieldsInput"];
  static values = {
    fields: String
  };

  connect() {
    let options = {}
    if (this.hasFieldsInputTarget) {
      const input = this.fieldsInputTarget;
      options = {
        mode: "code",
        onChangeText: (json) => {
          input.value = json;
        },
      };
    } else {
      options = {
        mode: "view"
      };
    }

    this.editor = new JSONEditor(this.editorTarget, options);
    // Parse and set initial JSON content
    try {
      const initialData = JSON.parse(this.fieldsValue);
      this.editor.set(initialData);
    } catch (error) {
      console.error("Invalid JSON in fieldsValue:", error);
      this.editor.set({});
    }
  }

  disconnect() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  }
}
