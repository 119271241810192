import { Controller } from "@hotwired/stimulus";  
import flatpickr from "flatpickr";    
  
export default class extends Controller {  
  static targets = ["input", "spinner"];  
  static values = { onChangeEventUrl: String };  
  
  connect() {  
    const onChangeEventUrl = this.onChangeEventUrlValue;  
      
    flatpickr(this.inputTarget, {  // Use the input target for Flatpickr  
      enableTime: false,
      dateFormat: "d M Y",
      minDate: new Date(),
      onChange: (selectedDates, dateStr, instance) => {
        this.showSpinner();
  
        if (onChangeEventUrl) {  
          // Create a Turbo Stream request  
          fetch(`${onChangeEventUrl}?date=${dateStr}`, {  
            method: 'GET',  
            headers: {  
              'Accept': 'text/vnd.turbo-stream.html',  // Expect Turbo Stream response  
              'Content-Type': 'application/json'  
            }  
          })  
          .then(response => response.text())  
          .then(Turbo.renderStreamMessage)  
          .finally(() => {
            // Hide the spinner after the request is complete
            this.hideSpinner();  
          });
        }  
      }  
    });  
  }  
  
  showSpinner() {
    if (this.spinnerTarget) {
      this.spinnerTarget.classList.remove("hidden");  
    }
  }
  
  hideSpinner() {
    if (this.spinnerTarget) {
      this.spinnerTarget.classList.add("hidden");  
    }  
  }  
}  
