import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "toggleButton"];
  static values = { delay: Number };

  connect() {
    if (this.hasDelayValue) {
      setTimeout(() => this.autoCollapse(), this.delayValue);
    }
  }

  toggle() {
    this.contentTarget.classList.toggle("hidden");
    if (this.hasToggleButtonTarget) {
      this.toggleButtonTarget.classList.toggle("hidden");
    }
  }

  autoCollapse() {
    this.contentTarget.classList.add("hidden");
    if (this.hasToggleButtonTarget) {
      this.toggleButtonTarget.classList.add("hidden");
    }
  }
}
