import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link", "menu"];
  
  toggleMenu() {
    this.menuTarget.classList.toggle("hidden")
  }

  setActive(e) {  
    this.resetAll(); // Reset all boxes  
    const clicked = e.currentTarget; // Get the clicked box
    clicked.classList.add("bg-blue-50", "border-blue-200"); // Add active class
    clicked.classList.remove("border-gray-300", "bg-white"); // Remove default class
  }

  resetActive(e) {
    this.resetAll()
  }

  resetAll() {
    this.linkTargets.forEach((item) => {
      item.classList.remove("bg-blue-50", "border-blue-200");
      item.classList.add("border-gray-300", "bg-white");
    });
  }
}
