const modules = import.meta.glob("./*_channel.js");

for (const path in modules) {
  modules[path]().then((module) => {
    if (typeof module.default === "function") {
      module.default();
    }
  });
}
import "./account_log_stream_channel"
