import consumer from "./consumer"

consumer.subscriptions.create("AccountLogStreamChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
  }
});


document.addEventListener("turbo:load", () => {
  const logElement = document.querySelector('[data-controller="log-stream"]');
  if (!logElement) return;

  const accountId = logElement.dataset.logStreamAccountId;

console.log("consumer...subscriptions.create(... ", accountId);
  consumer.subscriptions.create(
    { channel: "AccountLogStreamChannel", account_id: accountId },
    {
      received(data) {
        logElement.insertAdjacentHTML("beforeend", data);
      },
    }
  );
});