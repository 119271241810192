import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["templateSelect"];
  static values = { 
    stepId: Number,
    funnelId: Number
  }

  connect() {
    console.log("connect template select")
  }

  fetchTemplateDetails(event) {
    const templateId = event.target.value || 0;
    const stepId = this.stepIdValue;
    const funnelId = this.funnelIdValue;
    fetch(`/fun/funnels/${funnelId}/steps/load_template/${templateId}?fun_step_id=${stepId}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html);
    })
    .catch(error => {
      console.error('Error fetching template details:', error);
    });
  }
}