// app/javascript/controllers/mermaid_controller.js
import { Controller } from "@hotwired/stimulus"
import mermaid from "mermaid"

export default class extends Controller {
  static targets = ["chart"]

  connect() {
    mermaid.initialize({ startOnLoad: false })
    this.chartTargets.forEach((chart) => {
      mermaid.init(undefined, chart)
    })
  }
}

