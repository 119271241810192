// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log("Vite ⚡️ Rails");

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "@hotwired/turbo-rails";

document.addEventListener("turbo:load", () => {
  console.log("Turbo loaded!!!");
});

// <turbo-stream action="redirect" target="/projects"><template></template></turbo-stream>
// turbo_stream.action(:redirect, projects_path)
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

// Turbo.StreamActions.pulse = function () {
//   const element = document.querySelector(this.target);
//   if (!element) return;
//   element.classList.add("pulse");
//   setTimeout(() => {
//     element.classList.remove("pulse");
//   }, 1000);
// };

Turbo.StreamActions.reload_iframe = function () {
  const iframe = document.querySelector(`iframe#${this.target}`);
  if (!iframe) return;
  iframe.src = iframe.src;

  // Make the border of the iframe blink for 1s
  // iframe.style.border = "1px solid #aa0000";
  // setTimeout(() => {
  //   iframe.style.border = "";
  // }, 500);
};

import "../controllers";
import "../channels";

// import "polaris-view-components";
