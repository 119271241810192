import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  
  upload(event) {
    const file = event.target.files[0];
    if (file) {
      this.uploadFile(file);
    }
  }

  dragover(event) {
    event.preventDefault();
    this.element.querySelector('label').classList.add('border-blue-500');
  }

  dragleave(event) {
    event.preventDefault();
    this.element.querySelector('label').classList.remove('border-blue-500');
  }

  drop(event) {
    event.preventDefault();
    this.element.querySelector('label').classList.remove('border-blue-500');
    const file = event.dataTransfer.files[0];
    if (file) {
      this.uploadFile(file);
    }
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('mdi_image[file]', file);
    formData.append('mdi_image[slug]', file.name);

    fetch('/mdi/images', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Accept': 'text/vnd.turbo-stream.html'
      },
      body: formData
    }).then(response => response.text())
      .then(html => {
        const frame = document.querySelector('turbo-frame');
        frame.innerHTML = html;
      })
      .catch(error => {
        const statusElement = this.element.querySelector('#upload-status');
        statusElement.textContent = 'Failed to upload image.';
      });
  }
}
