import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabContent", "tabLink"];

  connect() {
    this.setupDragAndDrop();
  }

  showTab(event) {
    event.preventDefault();

    // Get target tab ID
    const targetId = event.currentTarget.getAttribute("href").substring(1);

    // Hide all tab contents and reset tab styles
    this.tabContentTargets.forEach((content) => {
      content.classList.add("hidden");
    });

    this.tabLinkTargets.forEach((tab) => {
      tab.classList.remove(
        "border-l",
        "border-t",
        "border-r",
        "rounded-t",
        "text-blue-700",
        "font-semibold",
        "border-b-0"
      );
      tab.classList.add("text-blue-500", "hover:text-blue-800", "border-b");
    });

    // Show the selected tab content and style the active tab
    this.tabContentTargets
      .find((content) => content.id === targetId)
      .classList.remove("hidden");

    event.currentTarget.classList.add(
      "border-l",
      "border-t",
      "border-r",
      "rounded-t",
      "text-blue-700",
      "font-semibold",
      "border-b-0"
    );
    event.currentTarget.classList.remove("text-blue-500", "hover:text-blue-800", "border-b");
  }

  setupDragAndDrop() {
    const dropArea = document.getElementById("drop-area");
    const fileInput = document.getElementById("file-input");

    dropArea.addEventListener("dragover", (event) => {
      event.preventDefault();
      dropArea.classList.add("border-blue-300");
    });

    dropArea.addEventListener("dragleave", () => {
      dropArea.classList.remove("border-blue-300");
    });

    dropArea.addEventListener("drop", (event) => {
      event.preventDefault();
      dropArea.classList.remove("border-blue-300");
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        fileInput.files = files;
        dropArea.querySelector("p").textContent = files[0].name;
      }
    });

    dropArea.addEventListener("click", () => {
      fileInput.click();
    });

    fileInput.addEventListener("change", () => {
      if (fileInput.files.length > 0) {
        dropArea.querySelector("p").textContent = fileInput.files[0].name;
      }
    });
  }
}
